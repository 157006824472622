import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'

function Layout() {
    return (
        
     <div className="overlay">
        <div className="element">
            <Navbar/>
            <Outlet/>
         </div>
        </div>
    )
}
export default Layout;







